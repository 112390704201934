<template>
	<WDialog title="审批" :showFooter="false" v-model="showDialog">
		<el-form ref="form" :model="form" label-width="80px">
			<el-form-item label="输入意见">
				<el-input v-model="form.comments"></el-input>
			</el-form-item>
			<el-form-item label="上传附件:">
				<FileUpload :value="form.attachments"></FileUpload>
			</el-form-item>
			<el-form-item label="签名画板" v-if="sign">
				<vue-esign ref="sign_canvas" :width="800" :height="300" />
				<el-button type="warning" @click="handleReset">
					清空画板
				</el-button>
				<el-button type="primary" @click="handleGenerate">
					生成图片
				</el-button>
			</el-form-item>
		</el-form>
		<div style="display: flex; justify-content: end">
			<el-button size="mini" type="warning" @click="handleRollback()">
				审核退回
			</el-button>
			<el-button size="mini" type="danger" @click="handleRejected()">
				审核驳回
			</el-button>
			<el-button size="mini" type="success" @click="handleApproved()">
				审核通过
			</el-button>
		</div>
	</WDialog>
</template>

<script>
import FileUpload from './FileUpload.vue'
export default {
	components: { FileUpload },
	data() {
		return {
			sign: false,
			form: {
				templateId: '',
				taskId: '',
				operator: sessionStorage.getItem('operator'),
				comments: '',
				signInfo: '',
				attachments: []
			},
			showDialog: false
		}
	},
	methods: {
		httpRequest() {},
		handleReset() {
			this.$refs['sign_canvas'].reset() //清空画布
		},
		handleGenerate() {
			this.$refs['sign_canvas']
				.generate()
				.then(res => {
					this.form.signInfo = res
					this.showDialog = true
					this.$message('生成成功')
				})
				.catch(err => {
					this.$message('未签名！')
				})
		},
		async initDialog(taskId, templateId) {
			this.form.taskId = taskId
			this.form.templateId = templateId
			const { sign } = await this.$api.flowable.templateInfo(templateId)
			this.sign = sign
			this.showDialog = true
		},
		handleRollback() {
			rollback(this.form).then(resp => {
				this.showDialog = false
				this.$message.success('退回成功')
				this.callback()
			})
		},
		handleRejected() {
			this.$api.flowable.rejected(this.form).then(resp => {
				this.showDialog = false
				this.$message.success('驳回成功')
				this.callback()
			})
		},
		handleApproved() {
			this.$api.flowable.approved(this.form).then(resp => {
				this.showDialog = false
				this.$message.success('审批成功')
				this.callback()
			})
		},
		callback() {
			this.$emit('refresh')
		}
	}
}
</script>

<style lang="less" scoped></style>
