<template>
	<div class="container">
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>审批中心</el-breadcrumb-item>
			<el-breadcrumb-item>我的待办</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="table">
			<el-row style="margin: 5px auto; float: right;">
				<el-button type="info" icon="el-icon-refresh" size="small" circle @click="listSelfTodo"></el-button>
			</el-row>
			<el-table
				:data="tableData"
				border
				stripe
				@row-dblclick="handleView"
			>
				<el-table-column type="index" label="#" />
				<el-table-column prop="templateName" label="申请单" />
				<el-table-column prop="unique" label="业务单号" />
				<el-table-column label="申请人">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user">
							{{ scope.row.applier?.nickname }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="流程状态">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user" :type="flowableType(scope.row.status)">
							{{ flowableStatus(scope.row.status) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="createTime"
					label="任务到达时间"
					width="160"
				/>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="info"
							@click="handleView(scope.row)"
						>
							流程图
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="handleAudit(scope.row)"
							v-if="scope.row.status == 1"
						>
							审核
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				layout="prev, pager, next, total"
				:current-page.sync="pageNo"
				@current-change="listSelfTodo"
				:total="total"
			/>
		</div>
		<AuditDialog ref="auditDialog" @refresh="listSelfTodo"></AuditDialog>
		<el-drawer
			title="流程图"
			:visible.sync="show"
			:modal-append-to-body="false"
			size="100%"
			direction="rtl"
			:modal="false"
			destroy-on-close
		>
			<div class="">
				<process-diagram-viewer />
			</div>
		</el-drawer>
	</div>
</template>
<script>
import AuditDialog from '../process/AuditDialog'
import ProcessDiagramViewer from '../../common/ProcessDiagramViewer'
import mixins from '../mixins/mixins'

export default {
	components: {
		AuditDialog,
		ProcessDiagramViewer
	},
	mixins: [mixins],
	data() {
		return {
			show: false,
			tableData: [],
			total: 0,
			pageNo: 1,
			pageSize: 50
		}
	},
	mounted() {
		this.listSelfTodo()
	},
	methods: {
		async listSelfTodo() {
			const operator = sessionStorage.getItem('operator')
			const { total, list } = await this.$api.flowable.selfTodo({
				operator: operator,
				pageNo: this.pageNo,
				pageSize: this.pageSize
			})
			this.total = total
			this.tableData = list
		},
		async handleView(row) {
			const {
				template,
				finishedNodeIds,
				runningNodeIds,
				waitingNodeIds
			} = await this.$api.flowable.instanceInfo({
				processInstanceId: row.processInstanceId,
				taskId: row.taskId
			})
			template.process = JSON.parse(template.process)
			this.$store.state.flowable.template = template
			this.$store.state.flowable.finishedNodeIds = finishedNodeIds
			this.$store.state.flowable.runningNodeIds = runningNodeIds
			this.$store.state.flowable.waitingNodeIds = waitingNodeIds
			this.show = true
		},
		handleAudit(row) {
			this.$refs.auditDialog.initDialog(row.taskId, row.templateId)
		}
	}
}
</script>
<style lang="less" scoped>
.container {
	.breadcrumb {
		margin-bottom: 10px;
	}
	.table {
		background-color: #fff;
		padding: 10px;
		border-radius: 5px;
	}
	.user {
		margin-right: 5px;
	}
}
</style>
